




import Vue from 'vue';
import { vuetifyComponents } from '@/plugins/vuetify';
import backend from '@/services/3xrCom';
import AdminTabs from '@/components/navigation/AdminTabs.vue';

interface ArchivizerMessageType {
  id: number;
  body: string;
  project_id: number;
  task_id: number;
}

interface ArchivizerProjectType {
  id: number;
  name: string;
  // more could be added
}

interface ArchivizerTaskType {
  id: number;
  name: string;
  // more could be added
}

interface ArchivizerFileAttachmentType {
  id: number;
  name: string;
  // more could be added
}

export default Vue.extend({
  name: 'admin-archivizer-api',
  metaInfo: {
    title: 'Archivizer API | 3XR',
  },
  components: {
    AdminTabs,
    ...vuetifyComponents,
  },
  mounted: function () {
    // nothing yet
  },
  data: () => ({
    fileAttachments: [] as ArchivizerFileAttachmentType[],
    fileAttachmentResult: '',
    messages: [] as ArchivizerMessageType[],
    messageResult: '',
    projects: [] as ArchivizerProjectType[],
    projectName: 'Test',
    projectDescription: 'API test from 3XR',
    projectEstimateDate: '2022-05-21T23:59:59.999Z',
    taskResult: '',
    tasks: [] as ArchivizerTaskType[],
    token: '',
    userFirstLastName: '',
  }),
  methods: {
    async deleteTaskById() {
      try {
        const taskId = (document.getElementById('taskId') as HTMLInputElement).value;
        if (taskId && confirm('Really delete this task?')) {
          const deleteTaskRequest = await backend.post('admin/archivizer/delete_task', { id: taskId });
          this.taskResult = deleteTaskRequest.data;
        }
      } catch (err) {
        this.taskResult = 'ERROR DELETING TASK';
      }
    },
    async loadFileAttachments() {
      try {
        const fileAttachmentsRequest = await backend.get('admin/archivizer/file_attachments');
        this.fileAttachments = fileAttachmentsRequest.data.data.map((fileAttachment: ArchivizerFileAttachmentType) => {
          return {
            id: fileAttachment.id,
            name: fileAttachment.name,
          };
        });
      } catch (err) {
        this.fileAttachments = [{ id: 0, name: 'ERROR LOADING FILE ATTACHMENTS' }];
      }
    },
    async loadMessages() {
      try {
        const messagesRequest = await backend.get('admin/archivizer/messages');
        this.messages = messagesRequest.data.data.map((message: ArchivizerMessageType) => {
          return {
            id: message.id,
            body: message.body,
            projectId: message.project_id,
            taskId: message.task_id,
          };
        });
      } catch (err) {
        this.messages = [{ id: 0, body: 'Error loading message', project_id: 0, task_id: 0 }];
      }
    },
    async loadProjects() {
      try {
        const projectsRequest = await backend.get('admin/archivizer/projects');
        this.projects = projectsRequest.data.data.map((project: ArchivizerProjectType) => {
          return {
            id: project.id,
            name: project.name,
          };
        });
      } catch (err) {
        this.projects = [{ id: 0, name: 'ERROR LOADING PROJECTS' }];
      }
    },
    async loadTasks() {
      try {
        const tasksRequest = await backend.get('admin/archivizer/tasks');
        this.tasks = tasksRequest.data.data.map((task: ArchivizerTaskType) => {
          return {
            id: task.id,
            name: task.name,
          };
        });
      } catch (err) {
        this.tasks = [{ id: 0, name: 'ERROR LOADING TASKS' }];
      }
    },
    async loadTaskById() {
      try {
        const taskId = (document.getElementById('taskId') as HTMLInputElement).value;
        if (taskId) {
          const taskRequest = await backend.get('admin/archivizer/task/' + taskId);
          this.taskResult = taskRequest.data;
        }
      } catch (err) {
        this.taskResult = 'ERROR LOADING TASK';
      }
    },
    async linkFileAttachmentToTask() {
      try {
        const taskId = (document.getElementById('taskId') as HTMLInputElement).value;
        const fileAttachmentId = (document.getElementById('fileAttachmentId') as HTMLInputElement).value;
        const linkRequest = await backend.post('admin/archivizer/link_file_attachment_to_task', {
          fileAttachmentId,
          taskId,
        });
        this.fileAttachmentResult = linkRequest.data;
      } catch (err) {
        this.fileAttachmentResult = 'ERROR ATTACHING FILE';
      }
    },
    async markTaskCompleteById() {
      try {
        const taskId = (document.getElementById('taskId') as HTMLInputElement).value;
        const request = await backend.post('admin/archivizer/mark_task_complete', { id: taskId });
        this.taskResult = request.data;
      } catch (err) {
        this.taskResult = 'ERROR MARKING TASK COMPLETE';
      }
    },
    async markTaskRevisionById() {
      try {
        const taskId = (document.getElementById('taskId') as HTMLInputElement).value;
        const request = await backend.post('admin/archivizer/mark_task_revision', { id: taskId });
        this.taskResult = request.data;
      } catch (err) {
        this.taskResult = 'ERROR MARKING TASK REVISION';
      }
    },
    async newProject() {
      try {
        const projectRequest = await backend.post('admin/archivizer/new_project', {
          name: this.projectName,
          description: this.projectDescription,
          estimateDate: this.projectEstimateDate,
        });
      } catch (err) {
        //console.log(err);
      }
    },
    async updateTaskDescriptionById() {
      try {
        const taskId = (document.getElementById('taskId') as HTMLInputElement).value;
        const request = await backend.get('admin/archivizer/update_task_description/' + taskId);
        this.taskResult = request.data;
      } catch (err) {
        this.taskResult = 'ERROR UPDATING TASK DESCRIPTION';
      }
    },
    async validateToken() {
      try {
        const tokenRequest = await backend.get('admin/archivizer/validate_token');
        const tokenInfo = tokenRequest.data;
        this.token = tokenInfo.token;
        this.userFirstLastName = tokenInfo.name + ' ' + tokenInfo.surname;
      } catch (err) {
        this.token = 'Unable to Load Token';
      }
    },
  },
});
